/* eslint-disable */

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const useAuth = () => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('type');

    if (role === 'superdistributor_employee') {
        if (token) {
            const decoded = jwt_decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return 1;
            } else {
                const user = token;
                return user && token;
            }
        } else {
            return 1;
        }
    } else {
        return 2;
    }
};

const ProtectedRouteSuperDistributor = ({ element }) => {
    const isAuth = useAuth();
    if (isAuth) {
        if (isAuth === 1) {
            return <Navigate to="/" replace />;
        } else if (isAuth === 2) {
            return <Navigate to="/unauthorized" replace />;
        } else {
            return element;
        }
    } else {
        return <Navigate to="/" replace />;
    }
};

export default ProtectedRouteSuperDistributor;

/* eslint-disable */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Space, Spin } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { Button, Modal, Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { checkMerchantOrBCAPI, checkPermissionGet } from 'utils/API/Merchant APIs/MerchantApiCalls';
import BcConvertModal from 'views/Components/BcConvertModal';
import { checkMerchantOrBc } from 'redux/ServiceRestrictSlice';
import Spinner from 'views/Components/LoadingSinner';
import { checkCustomerExistApi, fetchCustomerListAPi, getCustomerApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import { phoneValidationGlobal } from 'utils/Validations';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import ReKYCDialog from 'views/Components/Dialogs/ReKycDialog';
import DmtEkycExistingCustomers from './DmtEkycComponent/DmtEkycExistingCustomers';
import EditOptionComponent from '../EditOptionComponent';

export const dmType = localStorage.getItem('dmtType');

const commonStyles = {
    m: 0
};

export default function DmtTableIservue() {
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [checkExistLoading, setCheckExistLoading] = useState(false);
    const [isTrue, setIsTrue] = useState(false);
    const [isOnboarded, setIsOnboarded] = useState(false);
    const [iserveuLoading, setIserveuLoading] = useState(false);
    const [rowData, setRowData] = useState('');
    const [isGuestCustomer, setIsGuestCustomer] = useState(false); //Guest customer is a customer mapped under other agent who is under same distributor
    const [dmtType, setDmtType] = useState('');
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const [datas, setDatas] = useState('');
    const [filterDatas, setFilterDatas] = useState('');
    const [viewIndex, setViewIndex] = useState('');
    const [showEkycModal, setShowEkycModal] = useState('');
    const [reKycDetails, setReKycDetails] = useState({ shouldOpen: false });
    const [custId, setCustId] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const filteredData = customers?.filter((item) => item?.customermob?.includes(search));
    const location = useLocation();
    const data = location?.state?.createSuccessData;

    const handleKeyPress = (e) => {
        setShowEkycModal(false);
        const key = e.key;
        if (key === 'Enter') {
            e.preventDefault();
        }
    };
    const handleSearch = (e) => {
        let amountValue = e.target.value.replace(/[^\d]/g, '');
        if (amountValue.length > 0 && !['6', '7', '8', '9'].includes(amountValue[0])) {
            amountValue = '';
        }
        amountValue = amountValue.slice(0, 10);
        setSearch(amountValue);
    };
    const handleCreateClick = () => {
        setShowEkycModal(false);
        setIsTrue(false);
        if (search?.length === 10 && filteredData[0]?.customermob !== search) {
            checkCustomerExist();
        } else if (filteredData[0]?.customermob === search) {
            validateMobile();
            setIsTrue(true);
        } else {
            validateMobile();
        }
    };
    const showEdit = (newValue) => {
        setShowEditModal(true);
        setShowEditModal(newValue);
    };
    //MODAL HANDLERS
    const showModal = (newValue) => {
        setShowEkycModal(true);
        if (newValue === false) {
            setShowEkycModal(newValue);
        }
    };
    //UseEffect functions
    useEffect(() => {
        if (isTrue) {
            globalNotifyError('Customer Already Exists');
            setIsTrue(false);
        }
    }, [isTrue]);

    const handleTransferClick = (data, index) => {
        localStorage.setItem('sendernumber', data?.customermob);
        if (dmtType) {
            if (data?.iserveu_ekyc_status === 1) {
                const filteredData = customers;
                navigate('/dmt2/dmt-transfer', { state: { data, filteredData, index } });
            } else {
                setRowData(data);
                showModal();
                // getCustomerForIserveuChecking(data);
            }
        } else if (dmtType === 'billavenue') {
            if (data?.billavenue_status == '1') {
                const filteredData = customers;
                navigate('/dmt2/dmt-transfer', { state: { data, filteredData, index } });
            } else {
                setRowData(data);
                showModal();
                getCustomerForIserveuChecking(data);
            }
        }
    };

    const handleIserveuOnboard = (data, index) => {
        showModal();
        setRowData(data);
        // getCustomerForIserveuChecking(data);
    };

    //API call functions
    useEffect(() => {
        checkPermissionGet()
            .then((response) => {
                const dmtType = response?.data?.dmt_type;
                setDmtType(dmtType);
                const isWhitelabel = response?.data.whitelabel;
                if (!isWhitelabel) {
                    setReKycDetails({ shouldOpen: true });
                } else {
                    setReKycDetails({ shouldOpen: false });
                }
            })
            .catch((error) => console.log('error', error));
    }, []);
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    const customerDetails = async () => {
        try {
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');
            const payload = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payload);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);
                setIsCheckLoading(false);

                return;
            }
            setIsCheckLoading(false);
            setIsLoadingTable(true);
            setIsOnboarded(false);
            const response = await fetchCustomerListAPi();
            // console.log('dmt details : ', response);
            if (response?.data?.status) {
                const customerList = response?.data?.data?.customersList;
                setCustomers(customerList);
                setIsLoadingTable(false);
            } else {
                navigate('/unauthorized-section', { state: response?.data?.message });
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
            setIsCheckLoading(false);
        }
    };

    //API to check whether the entered phone number is registered under any of the agent of the same distributor
    const checkCustomerExist = async () => {
        if (phoneValidationGlobal(search)) {
            try {
                setCheckExistLoading(true);
                const phone = search;
                const payload = { phone };
                const response = await checkCustomerExistApi(payload);
                const data = response?.data;
                const senderno = search;
                setCheckExistLoading(false);
                if (response?.data?.success) {
                    if (response?.data?.exist) {
                        const custId = response?.data?.data[0]?.custId;
                        if (custId) {
                            navigate('/dmt2/dmt-beneficiarylist', { state: { search, data, senderno } });
                        } else {
                            setIsGuestCustomer(true);
                            const dataCustomer = response?.data?.data[0];
                            setRowData(dataCustomer);
                            getCustomerForIserveuChecking(dataCustomer);
                        }

                        // dmt / dmtTransfer;
                    } else {
                        const data = { customermob: search };
                        const isIserveuCheckSuccess = getCustomerAfterCheckCustomerFalse(data);
                        isIserveuCheckSuccess
                            .then((resolvedData) => {
                                if (resolvedData) {
                                    customerDetails();
                                } else {
                                    navigate('/dmt2/dmt-createcustomer', { state: { search } });
                                }
                            })
                            .catch((error) => {
                                console.log('error promise', error);
                            });
                    }
                }
            } catch (error) {
                setCheckExistLoading(false);

                console.log(error.message);
                handleCatchError(error);
            }
        } else {
            globalNotifyError('Please enter a valid mobile number.');
        }
    };

    //API TO CHECK THE ISERVEU ONBOARD STATUS OF SELECTED CUSTOMER
    const getCustomerForIserveuChecking = async (data) => {
        try {
            const CustomerMobileNo = data?.customermob;
            const agentid = localStorage.getItem('id');
            setIserveuLoading(true);
            const payload = { CustomerMobileNo, agentid };
            const response = await getCustomerApi(payload);
            if (response?.data?.status) {
                setIsOnboarded(true);
                return true;
            } else {
                setIsOnboarded(false);
                return false;
            }
        } catch (error) {
            setIserveuLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Check iserveu api for a different use after checkcustomerexist false response
    const getCustomerAfterCheckCustomerFalse = async (data) => {
        try {
            const CustomerMobileNo = data?.customermob;
            const agentid = localStorage.getItem('id');
            setIserveuLoading(true);
            const payload = { CustomerMobileNo, agentid };
            const response = await getCustomerApi(payload);
            return response?.data?.status;
        } catch (error) {
            setIserveuLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //API CALLS END HERE>>>>>>>>>>>>>>>>>>>>>>>>>>

    useEffect(() => {
        dispatch(fetchWalletBalanceAsync());
        dispatch(checkMerchantOrBc());
        customerDetails();
    }, []);
    // console.log('res in state :', responseData);
    function buttonView(item, index) {
        if (dmType) {
            if (!item?.iserveu_ekyc_status) {
                setDatas(item);
                setFilterDatas(customers);
                setViewIndex(index);
                localStorage.setItem('sendernumber', item?.customermob);
                showModal();
            } else {
                const filteredData = customers;
                navigate('/dmt2/dmt-beneficiarylist', { state: { item, filteredData, index } });
            }
        } else if (dmType === 'billavenue') {
            if (item?.billavenue_status == '1') {
                localStorage.setItem('sendernumber', item?.customermob);
                const filteredData = customers;
                navigate('/dmt2/dmt-beneficiarylist', { state: { item, filteredData, index } });
            } else {
                setIserveuLoading(true);
                setRowData(item);
                showModal();
                getCustomerForIserveuChecking(item);
            }
        }
    }
    useEffect(() => {
        if (data) {
            globalNotifySuccess(data);
        }
    }, [data]);
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(search)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    const handleEditClick = (row, index) => {
        setShowEditModal(true);
        setCustId(row?.id);
    };
    const listUpdate = (row, index) => {
        customerDetails();
    };
    return (
        <>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Domestic Money Transfer{' '}
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Domestic Money Transfer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { mb: 1, mt: 2, width: '30ch' }
                }}
                noValidate
                autoComplete="off"
            >
                {/* <FormControl> */}
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Please ensure you enter the Aadhaar-linked mobile number here, as the OTP required for verification will be sent to the mobile number associated with the provided Aadhaar number.">
                        <TextField
                            label="Search with Mobile"
                            name="mobile"
                            value={search}
                            onChange={handleSearch}
                            variant="outlined"
                            helperText={errorMsg}
                            error={Boolean(errorMsg)}
                            onKeyDown={handleKeyPress}
                            onBlur={validateMobile}
                        />
                    </Tooltip>

                    <Box sx={{ '& > :not(style)': {}, paddingTop: 1 }}>
                        <Tooltip title="Click to create new customer">
                            <Button
                                loading={checkExistLoading}
                                onClick={handleCreateClick}
                                shape="circle"
                                type="primary"
                                icon={<AddIcon />}
                            />
                            {/* <AddIcon /> */}
                            {/* </Button> */}
                        </Tooltip>
                    </Box>
                </Stack>

                {/* </FormControl> */}
            </Box>
            {isLoadingTable ? (
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                        paddingTop: 100
                    }}
                >
                    <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                </Space>
            ) : (
                <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sl. No</TableCell>
                                <TableCell align="right">Customer Name</TableCell>
                                <TableCell align="right">Mobile No.</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Onboarding Status</TableCell>
                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row, index) => (
                                <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell align="right">{row?.name + ' ' + row?.mname + ' ' + row?.lname}</TableCell>
                                    <TableCell align="right">{row?.customermob}</TableCell>
                                    <TableCell align="left">
                                        {
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleEditClick(row, index)}
                                            >
                                                Edit
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            <Button type="primary" variant="contained" size="small" onClick={() => buttonView(row, index)}>
                                                View
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleTransferClick(row, index)}
                                            >
                                                Transfer
                                            </Button>
                                        }
                                    </TableCell>
                                    {dmtType === 'iserveu' || 'fino' ? (
                                        !row?.iserveu_ekyc_status ? (
                                            <TableCell align="center">
                                                <Button
                                                    type="primary"
                                                    variant="contained"
                                                    size="small"
                                                    danger
                                                    onClick={() => handleIserveuOnboard(row, index)}
                                                >
                                                    Onboard Now
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <TableCell align="center">
                                                <Typography color="green">Onboarded</Typography>
                                            </TableCell>
                                        )
                                    ) : dmtType === 'billavenue' ? (
                                        !row?.iserveu_ekyc_status == '1' ? (
                                            <TableCell align="center">
                                                <Button
                                                    type="primary"
                                                    variant="contained"
                                                    size="small"
                                                    danger
                                                    onClick={() => handleIserveuOnboard(row, index)}
                                                >
                                                    Onboard Now
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <TableCell align="center">
                                                <Typography color="green">Onboarded</Typography>
                                            </TableCell>
                                        )
                                    ) : !row?.custId ? (
                                        <TableCell align="center">
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                danger
                                                // onClick={() => handleIserveuOnboard(row, index)}
                                            >
                                                Onboard Now
                                            </Button>
                                        </TableCell>
                                    ) : (
                                        <TableCell align="center">
                                            <Typography color="green">Onboarded</Typography>
                                        </TableCell>
                                    )}
                                    {/* <TableCell align="right">{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {showEkycModal && <DmtEkycExistingCustomers datas={datas} index={viewIndex} filteredData={filterDatas} showModal={showModal} />}
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    serviceName={'dmt'}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            <ToastContainer />
            <Spinner loading={isCheckLoading} />
            {reKycDetails.shouldOpen && <ReKYCDialog />}
            {showEditModal && <EditOptionComponent showEdit={showEdit} custId={custId} listUpdate={listUpdate} />}
        </>
    );
}

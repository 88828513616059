/* eslint-disable */
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { Fragment } from 'react';
const commonStyles = {
    m: 0
};
const BreadcrumbComponent = ({ routes }) => {
    return (
        /* <<<<<<<Breadcrumb starts here>>>>>> */

        <Box
            sx={{
                width: '100%',
                height: 'auto',
                backgroundColor: '#fff',
                ...commonStyles,
                borderRadius: 2,
                boxShadow: 2,
                marginBottom: 2,
                padding: '20px 10px',
                '@media screen and (min-width: 600px)': {
                    padding: 2
                }
            }}
        >
            <Typography variant="h3">
                <GlobalBackButton /> {routes[routes.length - 1].label}
            </Typography>

            <Box sx={{ marginTop: '10px' }}>
                <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <HomeOutlined />
                </Link>
                {routes.map((route, index) => {
                    return (
                        <Fragment key={index}>
                            <Typography component="span" style={{ margin: '0 10px' }}>
                                &gt;
                            </Typography>
                            <Link to={route?.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography component="span">{route.label}</Typography>
                            </Link>
                        </Fragment>
                    );
                })}
                {/* <Typography component="span" style={{ margin: '0 10px' }}>
          &gt;
        </Typography>
        <Typography component="span">Customer Communications</Typography> */}
            </Box>
        </Box>

        /* <<<<<<<Breadcrumb end here>>>>>>> */
    );
};

export default BreadcrumbComponent;

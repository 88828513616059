import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import styles from './styles/ListBatches.module.css';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const AccordionComponent = ({ batchData, isList, details, isAdmin, onApprove, onReject }) => {
    let formattedData;
    const transformBatchData = (transactions) => {
        // Convert the entire array into a single batch
        return {
            batch1: transactions?.map((transaction) => ({
                date: transaction.created_at.split(' ')[0],
                amount: parseFloat(transaction.amount),
                mdr: transaction.mdr_charge,
                type: transaction.service,
                txnId: transaction.txnId,
                rrn: transaction.rrn
            }))
        };
    };
    const transformBatchDataList = (batches) => {
        return batches.reduce((acc, batch) => {
            const batchKey = `Batch ${batch.batch_id}`;

            // Transform transactions to match table structure
            const transformedTransactions = batch.transactions?.map((transaction) => ({
                date: transaction.created_at,
                amount: parseFloat(transaction.amount),
                mdr: transaction.mdr_charge,
                type: transaction.service,
                txnId: transaction.txnId,
                rrn: transaction.rrn
            }));

            // Add batch metadata including settlement amount and status
            acc[batchKey] = {
                transactions: transformedTransactions,
                settlementAmount: batch.total_settlement_amount,
                status: batch.status,
                txnFrom: batch.txn_from,
                txnTo: batch.txn_to,
                batch_id: batch.batch_id
            };

            return acc;
        }, {});
    };
    if (isList) {
        formattedData = transformBatchDataList(batchData);
    } else {
        formattedData = transformBatchData(batchData);
    }

    return (
        <>
            <Box className={styles.accordionContainer}>
                {Object.entries(formattedData)?.map(([batchName, data]) => (
                    <Accordion key={batchName} className={styles.accordion} TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#64748b' }} />}
                            aria-controls={`${batchName}-content`}
                            id={`${batchName}-header`}
                            className={styles.accordionHeader}
                        >
                            <div className={styles.summaryContent}>
                                <Typography className={styles.batchTitle}>
                                    {batchName.charAt(0).toUpperCase() + batchName.slice(1)}
                                </Typography>
                                <Typography className={styles.settlementAmount}>
                                    ₹ {isList ? data.settlementAmount.toLocaleString() : details.total_settlement_amount?.toLocaleString()}
                                </Typography>
                                <Typography className={`${styles.status} ${styles[data.status?.toLowerCase()]}`}>{data.status}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {isAdmin && (
                                <div className={styles.actionButtonsContainer}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={styles.approveButton}
                                        onClick={() => onApprove(batchName, data)}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        className={styles.rejectButton}
                                        onClick={() => onReject(batchName, data)}
                                    >
                                        Reject
                                    </Button>
                                </div>
                            )}
                            <TableContainer component={Paper} className={styles.tableContainer}>
                                <Table className={styles.table}>
                                    <TableHead>
                                        <TableRow className={styles.tableHeader}>
                                            <TableCell className={styles.tableHeaderCell}>Date</TableCell>
                                            <TableCell className={styles.tableHeaderCell}>Amount</TableCell>
                                            <TableCell className={styles.tableHeaderCell}>MDR</TableCell>
                                            <TableCell className={styles.tableHeaderCell}>Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isList
                                            ? data.transactions?.map((row, index) => (
                                                  <TableRow key={index} className={styles.tableRow}>
                                                      <TableCell className={styles.tableCell}>
                                                          {new Date(row.date).toLocaleDateString('en-US', {
                                                              year: 'numeric',
                                                              month: 'short',
                                                              day: 'numeric'
                                                          })}
                                                      </TableCell>
                                                      <TableCell className={`${styles.tableCell} ${styles.amountCell}`}>
                                                          ₹ {row.amount.toLocaleString()}
                                                      </TableCell>
                                                      <TableCell className={styles.tableCell}>₹ {row.mdr}</TableCell>
                                                      <TableCell className={styles.tableCell}>
                                                          <span
                                                              className={`
                                      ${styles.typeCell} 
                                      ${row.type === 'Credit' ? styles.typeCredit : styles.typeDebit}
                                  `}
                                                          >
                                                              {row.type}
                                                          </span>
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                            : data?.map((row, index) => (
                                                  <TableRow key={index} className={styles.tableRow}>
                                                      <TableCell className={styles.tableCell}>
                                                          {new Date(row.date).toLocaleDateString('en-US', {
                                                              year: 'numeric',
                                                              month: 'short',
                                                              day: 'numeric'
                                                          })}
                                                      </TableCell>
                                                      <TableCell className={`${styles.tableCell} ${styles.amountCell}`}>
                                                          ₹ {row.amount.toLocaleString()}
                                                      </TableCell>
                                                      <TableCell className={styles.tableCell}>₹ {row.mdr}</TableCell>
                                                      <TableCell className={styles.tableCell}>
                                                          <span
                                                              className={`
                                      ${styles.typeCell} 
                                      ${row.type === 'Credit' ? styles.typeCredit : styles.typeDebit}
                                  `}
                                                          >
                                                              {row.type}
                                                          </span>
                                                      </TableCell>
                                                  </TableRow>
                                              ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </>
    );
};
export default AccordionComponent;
AccordionComponent.propTypes = {
    batchData: PropTypes.array,
    isList: PropTypes.bool,
    details: PropTypes.object,
    isAdmin: PropTypes.bool,
    onApprove: PropTypes.func,
    onReject: PropTypes.func
};

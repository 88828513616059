// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconWallet,
    IconArrowBigUpLines,
    IconDeviceMobile,
    IconHandFinger,
    IconArrowsTransferDown,
    IconQrcode,
    IconCreditCard,
    IconFingerprint,
    IconDeviceMobileMessage,
    IconMoneybag,
    IconCircleNumber2
    // IconArrowAutofitRight
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,

    IconPalette,
    IconShadow,
    IconWindmill,
    IconWallet,
    IconArrowBigUpLines,
    IconDeviceMobile,
    IconHandFinger,
    IconArrowsTransferDown,
    IconQrcode,
    IconCreditCard,
    IconFingerprint,
    IconDeviceMobileMessage,
    IconMoneybag,
    IconCircleNumber2
    // IconArrowAutofitRight
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //
const mobileBankingStatus = localStorage.getItem('mobileBanking');
// const whiteLabelStatue = localStorage.getItem('whitelabelStatus');
const Wallets = {
    id: 'newUtilities',
    title: 'Banking Services',
    type: 'group',
    children: [
        {
            id: 'icons2',
            title: 'Domestic Money Transfer',
            type: 'collapse',
            icon: icons.IconArrowsTransferDown,
            children: [
                {
                    id: 'dmtFino',
                    title: 'DMT 1',
                    type: 'item',
                    url: 'dmt/dmtTable',
                    breadcrumbs: false,
                    icon: icons.IconMoneybag
                },
                {
                    id: 'dmtIserVue',
                    title: 'DMT 2',
                    type: 'item',
                    url: 'dmt2/dmt-customerlist',
                    breadcrumbs: false,
                    icon: icons.IconMoneybag
                }
            ]
        },
        {
            id: 'matm',
            title: 'MATM',
            type: 'collapse',
            icon: icons.IconDeviceMobile,
            children: [
                {
                    id: 'matm4',
                    title: 'Multi-Purpose Onboarding',
                    type: 'item',
                    url: 'matm/iserveu-matm-registration',
                    caption: 'AEPS/MATM/POS',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons9986',
            title: 'AEPS',
            type: 'collapse',
            icon: icons.IconHandFinger,
            children: [
                {
                    id: 'tabler-icons5',
                    title: 'Balance Enquiry',
                    type: 'item',
                    url: 'aeps/balanceEnquiry',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons6',
                    title: 'Cash Withdrawal',
                    type: 'item',
                    url: 'aeps/cashWithdrawal',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons7',
                    title: ' Mini Statement ',
                    type: 'item',
                    url: 'aeps/miniStatementAadhaar',
                    breadcrumbs: false
                }
                // {
                //     id: 'tabler-icons8',
                //     title: ' Aadhaar Pay ',
                //     type: 'item',
                //     url: 'aeps/aadhaarpay',
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'icons07',
            title: 'Aadhaar Pay',
            type: 'item',
            icon: icons.IconFingerprint,
            url: 'aeps/aadhaarpay',
            breadcrumbs: false
            // children: [
            //     {
            //         id: 'tabler-icons1',
            //         title: 'Instant Transfer',
            //         type: 'item',
            //         url: 'dmt/dmtTable',
            //         breadcrumbs: false
            //     }
            // ]
        },

        {
            id: 'tabler-icons881',
            title: 'My QR',
            type: 'item',
            icon: icons.IconQrcode,
            url: 'qr/myqr',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons882',
            title: 'UPI ATM',
            type: 'item',
            icon: icons.IconCreditCard,
            url: '/atmupi',
            breadcrumbs: false
        },
        // {
        //     id: 'tabler-icons8822',
        //     title: 'Credit',
        //     type: 'item',
        //     icon: icons.IconArrowAutofitRight,
        //     url: '/credit',
        //     breadcrumbs: false
        // },
        mobileBankingStatus === 'true'
            ? {
                  id: 'tabler-icons8821',
                  title: 'Mobile Banking',
                  type: 'item',
                  icon: icons.IconDeviceMobileMessage,
                  url: 'mobile-banking/mobilebanking-list',
                  breadcrumbs: false
              }
            : null
    ].filter(Boolean)
};

export default Wallets;

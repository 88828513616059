import Table from '@mui/material/Table';
/* eslint-disable */
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'antd';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Space, Spin } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
import { useDispatch } from 'react-redux';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI, checkPermissionGet } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import {
    getCustomerV2Api,
    reduxIndexverifysender_dmt_api
} from 'utils/API/Merchant APIs/DmtApiCalls';
import withAccessControlResetPassword from 'utils/HOC/resetPasswordHOC';
import ReKYCDialog from 'views/Components/Dialogs/ReKycDialog';
import BioAuthExistingUsers from './DmtAuthComponents/BioAuthExistingUsers';
import EditOptionComponent from '../EditOptionComponent';
const commonStyles = {
    m: 0
};

const DenseTable = () => {
    const uid = localStorage.getItem('id');
    const dmt_type = 1;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const [checkExistLoading, setCheckExistLoading] = useState(false);
    const [reKycDetails, setReKycDetails] = useState({ shouldOpen: false });
    const [showAuth, setShowAuth] = useState(false);
    const [dataForEkyc, setDataForEkyc] = useState([]);
    const [filtere, setFiltered] = useState();
    const [Index, setIndex] = useState();
    const [custId, setCustId] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');

    const filteredData = customers?.filter((item) => item?.customermob?.includes(search));
    const location = useLocation();
    const data = location?.state?.createSuccessData;
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    const handleCreateClick = () => {
        if (search?.length === 10 && filteredData[0]?.customermob !== search) {
            checkCustomerExist();
        } else if (filteredData[0]?.customermob === search) {
            validateMobile();
            globalNotifyError('Customer Already Exists!');
        } else {
            validateMobile();
        }
    };

    const handleTransferClick = (data, index) => {
        const filteredData = customers;
        localStorage.setItem('sendernumber', data?.customermob);
        if (data?.fino_ekyc_status === 1) {
            navigate('/dmt/fundTransfer', { state: { data, filteredData, index } });
        } else {
            setShowAuth(true);
        }
    };
    const verifyRekycStatus = async () => {
        const permissionResponse = await checkPermissionGet();
        if (permissionResponse?.status === 200) {
            const isWhitelabel = permissionResponse?.data.whitelabel;
            if (!isWhitelabel) {
                setReKycDetails({ shouldOpen: true });
            } else {
                setReKycDetails({ shouldOpen: false });
            }
        }
    };
    //API call functions
    const customerDetails = async () => {
        try {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');
            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
            setIsLoadingTable(true);
            const transfer = 'fino-transfer';
            const payload = { uid, transfer };
            const response = await reduxIndexverifysender_dmt_api(payload);
            if (response?.data?.status) {
                const customerList = response?.data?.data?.customersList;
                setCustomers(customerList);
                setIsLoadingTable(false);
            } else {
                navigate('/unauthorized-section', { state: response?.data?.message });
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API to check whether the entered phone number is registered under any of the agent of the same distributor
    const checkCustomerExist = async () => {
        try {
            // setIsLoadingTable(true);
            setCheckExistLoading(true);
            const phone = search;
            // const payload = { phone };
            // const response = await checkCustomerExistApi(payload);
            const agentid = localStorage.getItem('id');
            const payload = { agentid, CustomerMobileNo: phone, dmt_type };
            const response = await getCustomerV2Api(payload);
            // console.log('response', response);
            setCheckExistLoading(false);
            const data = response?.data?.data;
            const senderno = search;
            if (response?.data?.success) {
                navigate('/dmt/dmtTransfer', { state: { search, data, senderno } });
                // dmt / dmtTransfer;
            } else {
                navigate('/dmt/createCustomer', { state: { search } });
            }
        } catch (error) {
            setCheckExistLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    useEffect(() => {
        dispatch(fetchWalletBalanceAsync());
        customerDetails();
        verifyRekycStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // console.log('res in state :', responseData);
    const showModal = (newValue) => {
        setShowAuth(true);
        setShowAuth(newValue);
    };
    const showEdit = (newValue) => {
        setShowEditModal(true);
        setShowEditModal(newValue);
    };
    function buttonView(item, index) {
        localStorage.setItem('sendernumber', item?.customermob);
        const filteredDatas = customers;
        setDataForEkyc(item);
        setFiltered(filteredDatas);
        setIndex(index);
        if (item?.fino_ekyc_status === 1) {
            navigate('/dmt/dmtTransfer', { state: { item, filteredData, index } });
        } else {
            setShowAuth(true);
        }
    }
    useEffect(() => {
        if (data) {
            globalNotifySuccess('Customer created successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(search)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    const handleEditClick = (row, index) => {
        setShowEditModal(true);
        setCustId(row?.id);
    };
    const listUpdate = (row, index) => {
        customerDetails();
    };
    return (
        <>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton />
                    Domestic Money Transfer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Domestic Money Transfer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { mb: 1, mt: 2, width: '30ch' }
                }}
                noValidate
                autoComplete="off"
            >
                {/* <FormControl> */}
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Please ensure you enter the Aadhaar-linked mobile number here, as the OTP required for verification will be sent to the mobile number associated with the provided Aadhaar number.">
                        <TextField
                            label="Search with Mobile"
                            name="mobile"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            variant="outlined"
                            helperText={errorMsg}
                            error={Boolean(errorMsg)}
                            onBlur={validateMobile}
                        />
                    </Tooltip>

                    {/* <Button type="submit" variant="contained">
                            Verify
                        </Button> */}
                    <Box sx={{ '& > :not(style)': {}, paddingTop: 1 }}>
                        <Tooltip title="Click to create new customer">
                            <Button
                                loading={checkExistLoading}
                                onClick={handleCreateClick}
                                shape="circle"
                                type="primary"
                                icon={<AddIcon />}
                            />
                        </Tooltip>
                    </Box>
                </Stack>

                {/* </FormControl> */}
            </Box>
            {isLoadingTable ? (
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                        paddingTop: 100
                    }}
                >
                    <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                </Space>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sl. No</TableCell>
                                <TableCell align="left">Customer Name</TableCell>
                                <TableCell align="left">Mobile No.</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData?.map((row, index) => (
                                <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="left">{row?.name + ' ' + row?.mname + ' ' + row?.lname}</TableCell>
                                    <TableCell align="left">{row?.customermob}</TableCell>
                                    <TableCell align="left">
                                        {
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleEditClick(row, index)}
                                            >
                                                Edit
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        {
                                            <Button type="primary" variant="contained" size="small" onClick={() => buttonView(row, index)}>
                                                View
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        {
                                            <Button
                                                type="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleTransferClick(row, index)}
                                            >
                                                Transfer
                                            </Button>
                                        }
                                    </TableCell>
                                    {/* <TableCell align="right">{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    serviceName={'dmt'}
                    shouldshowEsign={shouldshowEsign}
                />
            )}

            <Spinner loading={isCheckLoading} />
            {reKycDetails.shouldOpen && <ReKYCDialog />}
            {showAuth && <BioAuthExistingUsers showModal={showModal} datas={dataForEkyc} index={Index} filteredData={filtere} />}
            {showEditModal && <EditOptionComponent showEdit={showEdit} custId={custId} listUpdate={listUpdate} />}
        </>
    );
};

export default withAccessControlResetPassword(DenseTable);

import { Badge, Row, Col } from 'antd';
import { motion } from 'framer-motion';
import styles from './CommissionDisplay.module.css';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
const CommissionDisplayComponent = ({ amountResponse, isSilver }) => {
    const navigate = useNavigate();

    const plans = [
        {
            plan: 'Normal Plan',
            gst: amountResponse ? amountResponse.gst : '',
            totalCommission: amountResponse ? amountResponse.totCommi : '',
            tds: amountResponse ? amountResponse.tds : '',
            totalDebitAmount: amountResponse ? amountResponse.agentDebitAmount : '',
            isCurrent: isSilver ? false : true,
            styleClass: styles.normalPlan
        },
        {
            plan: 'Silver Plan',
            gst: '₹ 90',
            totalCommission: amountResponse?.silver,
            tds: '₹ 40',
            totalDebitAmount: '₹ 5640',
            isCurrent: isSilver ? true : false,
            styleClass: styles.silverPlan
        },
        {
            plan: 'Gold Plan',
            gst: '₹ 80',
            totalCommission: amountResponse?.gold,
            tds: '₹ 30',
            totalDebitAmount: '₹ 5630',
            isCurrent: false,
            styleClass: styles.goldPlan
        }
    ];
    return (
        <>
            {/* Cards Layout */}
            <Row
                gutter={[16, 16]}
                className={styles.row}
                onClick={() => navigate('/premium-bc/premium-bc-class-cards')}
                style={{ cursor: 'pointer' }}
            >
                {plans
                    ?.filter((cardData) => !(isSilver && cardData.plan === 'Normal Plan'))
                    ?.map((cardData, index) => (
                        <Col
                            xs={24} // Full width on extra small devices
                            sm={12} // Half width on small devices
                            md={8} // One-third width on medium and larger devices
                            key={index}
                        >
                            <Badge.Ribbon
                                text={cardData.isCurrent ? 'Normal Commission' : 'Higher Commission'}
                                color={cardData.isCurrent ? 'blue' : 'green'}
                                placement="start"
                                className={styles.ribbon}
                            >
                                <motion.div
                                    className={`${styles.card} ${cardData.styleClass}`}
                                    whileHover={{
                                        scale: 1.05,
                                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                        transition: { duration: 0.3 }
                                    }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <div className={`${styles.card} ${cardData.styleClass}`}>
                                        <p className={styles.planTitle}>{cardData.plan}</p>
                                        <p className={styles.commissionData}>
                                            Total Commission:
                                            <span className={styles.commissionAmount}> {cardData.totalCommission}</span>
                                            <span className={styles.additionalInfo}> (*including GST)</span>
                                        </p>
                                        {!cardData.isCurrent ? (
                                            <motion.button
                                                className={styles.button}
                                                onClick={() => navigate('/premium-bc/premium-bc-class-cards')}
                                                animate={{
                                                    scale: [1, 1.1, 1]
                                                }}
                                                transition={{
                                                    duration: 1,
                                                    repeat: Infinity,
                                                    repeatType: 'reverse'
                                                }}
                                            >
                                                Unlock now
                                            </motion.button>
                                        ) : (
                                            <button className={styles.buttonCurrent}>Current Plan</button>
                                        )}
                                    </div>
                                </motion.div>
                            </Badge.Ribbon>
                        </Col>
                    ))}
            </Row>
        </>
    );
};

export default CommissionDisplayComponent;
CommissionDisplayComponent.propTypes = {
    amountResponse: PropTypes.object,
    isSilver: PropTypes.object
};

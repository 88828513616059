import React from 'react';
/* eslint-disable */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { handleCatchError } from 'utils/CatchHandler';
import { customerEditApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import Spinner from 'views/Components/LoadingSinner';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
const commonStyles = {
    m: 0
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function EditOptionComponent({ showEdit, custId, listUpdate }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    console.log('showModal', showEdit);
    const agentid = localStorage.getItem('id');
    useEffect(() => {
        if (showEdit) {
            setOpen(true);
        }
    }, [showEdit]);
    //API CALL FOR EDIT
    const handleEkyc = async () => {
        try {
            setIsLoading(true);
            const CustomerName = name;
            const payload = {
                agentid,
                CustomerName,
                custId
            };
            const response = await customerEditApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
            }
            handleClose();
            listUpdate();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const validateName = () => {
        const re = /^[a-zA-Z]+(?:-[a-zA-Z]+)*(?: [a-zA-Z]+(?:-[a-zA-Z]+)*)*$/;
        if (!re.test(name)) {
            const fErr = 'Please enter a valid name';
            setNameError(fErr);
        } else {
            setNameError('');
        }
    };
    const handleName = (e) => {
        let inputValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        inputValue = inputValue.slice(0, 50);
        setName(inputValue);
    };
    const handleClose = () => {
        setOpen(false);
        showEdit(false);
    };
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%',
                        maxWidth: '600px',
                        margin: '0 auto',
                        padding: { xs: '10px', sm: '20px' },
                        '@media (max-width: 600px)': {
                            width: '90%'
                        }
                    }
                }}
            >
                <DialogTitle>{'Enter your Full Name'}</DialogTitle>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        ...commonStyles,
                        borderRadius: 2,
                        marginBottom: 2,
                        padding: { xs: '10px', sm: '20px' }
                    }}
                >
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={name}
                                    onChange={handleName}
                                    helperText={nameError}
                                    error={Boolean(nameError)}
                                    onBlur={validateName}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button variant="contained" fullWidth disabled={isLoading || !name} onClick={handleEkyc}>
                                Submit
                            </Button>
                        </DialogActions>
                    </>
                </Box>
            </Dialog>

            <Spinner loading={isLoading} />
        </>
    );
}

export default EditOptionComponent;

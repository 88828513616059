import Box from '@mui/material/Box';
/* eslint-disable */
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from 'antd';
import { Space } from 'antd';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { HomeOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { Modal, Row, Col } from 'antd';
import { Button as AntButton } from 'antd';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { useSelector } from 'react-redux';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import {
    generateOtpApi,
    getDmtChargesApi,
    getIservueLimitApi,
    indexVerifySenderAPI,
    loadTransferApi,
    moneytransferBenefDetailsApi
} from 'utils/API/Merchant APIs/DmtApiCalls';
import { checkMerchantOrBCAPI, getPremiumBcChargesNewApi } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { setDisablerFalse, setDisablerTrue } from 'redux/SidebarDisablerSlice';
import { useDispatch } from 'react-redux';
import FundTransferEkyc from './DmtEkycComponent/FundTransferEkyc';
import CommissionDisplayComponent from '../CommissionDisplayComponent';
const commonStyles = {
    m: 0
};

export default function DenseTable() {
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [paymentMode, setPaymentMode] = useState('IMPS');
    const [selectedBenefData, setSelectedBenefData] = useState('');
    const [amountResponse, setAmountResponse] = useState('');
    const [amountError, setAmountError] = useState('');
    //Dropdown helpers
    const [customer, setcustomer] = useState('');
    const [beneficiary, setBeneficiary] = useState('');
    //Arrays to map
    const [customerMap, setCustomerMap] = useState([]);
    const [beneficiaryMap, setBeneficiaryMap] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [choosedBenef, setChoosedBenef] = useState(null);

    //State to store amount
    const [tamount, setTamount] = useState();
    //Is loading state for button change
    const [isLoading, setIsLoading] = useState(false);
    const [maxLimitLoading, setMaxLimitLoading] = useState(false);
    const [senderno, setSenderno] = useState('');
    const [maxLimit, setMaxLimit] = useState(0);
    //State to check the transfer is success
    const isSuccess = true;
    const isFirstRender = true;
    const [isTrue, setIsTrue] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isFailedMessage, setIsFailedMessage] = useState('');
    const [benifId, setBenifId] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [OtpRefrenceId, setOtpRefrenceId] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const CreateCustomerRes = location?.state?.Data;
    const transfer = 'iserveu';
    const id = location?.state?.data?.id || location?.state?.data?.data[0]?.id;
    const indexBenef = location?.state?.indexBenef;
    const benefId = location?.state?.row?.benefId;
    const uid = localStorage.getItem('id');
    const email = localStorage.getItem('email_id');
    const data = location?.state?.item || location?.state?.data;
    const type = 'fino';
    const benAcc = selectedBenefData?.beneAccountNo;
    const amount = amountResponse?.amount;
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    //States and handlers for modal component
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const [plansToDisplay, setPlansToDisplay] = useState({ normal: {}, silver: {}, gold: {} });

    // const [modalText, setModalText] = useState('Content of the modal');

    //w1 balance
    const balanceW1 = useSelector((state) => state?.wallet?.balance?.roundedNumber);
    const handleTransferClick = () => {
        setIsFailedMessage('');
        setIsFailed(false);
        if (tamount >= 100) {
            fetchValues();
        } else {
            setAmountError('The minimum transfer amount is 100!');
        }
    };
    const showModal = () => {
        if (customer && beneficiary && tamount) {
            setOpen(true);
        } else {
            globalNotifyError('Make sure you have filled all the fields!');
            setIsLoading(false);
        }
    };
    const handleOk = () => {
        setConfirmLoading(true);
        handleGenerateOtp();
        handleCancel();
    };
    const handleCancel = () => {
        setConfirmLoading(false);
        setOpen(false);
        dispatch(setDisablerFalse());
    };

    //STATE MANAGEMENT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    //Functions to API calls>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const getPremiumPlansAndCharges = async () => {
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const response = await getPremiumBcChargesNewApi(payload);
        const data = response?.data?.data;
        const normalPlan = data?.find((item) => item.id === 1);
        const silverPlan = data?.find((item) => item.id === 3);
        const goldPlan = data?.find((item) => item.id === 2);
        setPlansToDisplay({ normal: normalPlan, silver: silverPlan, gold: goldPlan });
    };
    const customerDetails = async () => {
        try {
            const payload = { uid, email, senderno, type };
            const response = await moneytransferBenefDetailsApi(payload);
            const payloadIndex = { uid };
            const customerApi = await indexVerifySenderAPI(payloadIndex);
            const customerList = customerApi?.data?.data?.customersList;
            const benefData = response?.data?.data?.benfDetails;
            setCustomerMap(customerList);
            setBeneficiaryMap(benefData);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Api to fetch values when amount entered
    const fetchValues = async () => {
        const serviceType = 'DMT';
        setIsTrue(true);
        try {
            const payload = { uid, transfer, paymentMode, tamount, serviceType };
            const values = await getDmtChargesApi(payload);
            setAmountResponse(values?.data);
            showModal();
            setIsTrue(false);
            showModal();
        } catch (error) {
            console.log(error.message);
            setIsTrue(false);
            handleCatchError(error);
        }
    };

    //Api to fetch data of single beneficiary on change of dropdown
    const fetchBenefDetails = async () => {
        const type = transfer;
        const id = beneficiary;
        try {
            const payload = { email, type, id, senderno };
            const singleBenefData = await loadTransferApi(payload);
            const detailsOfBenef = singleBenefData?.data?.data;
            setSelectedBenefData(detailsOfBenef);
            setBenifId(detailsOfBenef?.id);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //UPDATE BENEFICIARY AS PER THE SELECTED CUSTOMER
    const updateBeneficiary = async () => {
        try {
            const payload = { uid, email, senderno, type };
            const response = await moneytransferBenefDetailsApi(payload);
            const benefData = response?.data?.data?.benfDetails;
            setBeneficiaryMap(benefData);
        } catch (error) {
            console.log(error);
            handleCatchError(error);
        }
    };
    const checkMerchantBC = async () => {
        setIsCheckLoading(true);
        const agentid = localStorage.getItem('id');

        const payloadCheck = { agentid };
        const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
        setIsCheckLoading(false);
        const agentType = merchantBcCheckResponse?.data?.agentType;
        const pendingStatus = merchantBcCheckResponse?.data?.isPending;
        setIsBcConvertPending(pendingStatus);

        if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
            setMerchantModalOpen(true);

            return;
        }
        //CHECKING ENDS HERE
    };
    const getTransactionLimit = async () => {
        try {
            setMaxLimitLoading(true);
            const CustomerMobileNo = data?.customermob;
            const type = 'iserveu';
            const payload = { CustomerMobileNo, type };
            const response = await getIservueLimitApi(payload);

            const maximumLimit = response?.data?.limit;
            if (maximumLimit >= 0) {
                setMaxLimitLoading(false);
                setMaxLimit(maximumLimit);
            } else {
                setMaxLimitLoading(false);
                setMaxLimit(0);
            }
            setMaxLimitLoading(false);
        } catch (error) {
            setMaxLimitLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //otp api
    const handleGenerateOtp = async () => {
        try {
            setIsLoading(true);
            const agentid = localStorage.getItem('id');
            const customerId = id;
            const BeneName = selectedBenefData?.beneName;
            const paymentMode = 'IMPS';
            const BeneAccountNo = benAcc;
            const dmt_type = 2;
            const payload = {
                agentid,
                BeneName,
                customerId,
                BeneAccountNo,
                amount,
                dmt_type,
                paymentMode
            };
            const response = await generateOtpApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setOtpRefrenceId(response?.data?.OTPReferenceID);
                setShowOtpField(true);
            } else {
                // handleClose();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //FUNCTION TO FETCH SENDER NUMBER ON CUSTOMER CHANGE
    //EVENT HANDLERS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleModeChange = (event) => {
        event.preventDefault();
        setPaymentMode(event.target.value);
    };
    const handleBeneficiaryChange = (event, newValue) => {
        if (newValue?.id) {
            setBeneficiary(newValue?.id);
            setChoosedBenef(newValue);
        }
    };
    const handleAmountChange = (e) => {
        setAmountError('');
        const amountValue = e?.target?.value;
        const amountValueFloat = parseFloat(amountValue);
        const balanceW1Float = parseFloat(balanceW1);
        if (amountValueFloat > 0) {
            if (amountValueFloat <= balanceW1Float) {
                setTamount(amountValue);
            } else {
                setAmountError('Entered amount exceeds the wallet balance!');
            }
        } else {
            setAmountError('Please enter a valid amount.');
        }
        if (amountValueFloat > 0) {
            if (amountValue <= maxLimit) {
                setTamount(amountValue);
            } else {
                setAmountError('The entered amount exceeds the maximum limit');
            }
        } else {
            setAmountError('Please enter a valid amount.');
        }
    };

    const handleAddBenefClick = () => {
        navigate('/dmt2/dmt-addbeneficiary', { state: { data: selectedCustomer } });
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    useEffect(() => {
        if (open) {
            dispatch(setDisablerTrue());
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (senderno) {
            customerDetails();
            updateBeneficiary();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    //calling  transaction limit api to reassurance of loading of transaction limit>>>>>>>>>
    useEffect(() => {
        if (senderno) {
            getTransactionLimit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderno, customer]);

    useEffect(() => {
        checkMerchantBC();
        getPremiumPlansAndCharges();
        if (CreateCustomerRes) {
            globalNotifySuccess('Customer created successfully');
        }
        const allCustomers = location?.state?.filteredData;
        const filteredCustomer = allCustomers?.filter((data) => data?.id === id);
        if (filteredCustomer?.length > 0) {
            const number = filteredCustomer[0]?.customermob;
            setSenderno(number);
        } else {
        }
        if (location?.state?.senderno) {
            setSenderno(location?.state?.senderno);
        }
        setcustomer(id);
        const beneficiaryFromNavigate = location?.state?.row ? location?.state?.row?.id : '';
        setBeneficiary(beneficiaryFromNavigate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tamount >= 100) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tamount]);
    useEffect(() => {
        if (beneficiary) {
            fetchBenefDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiary]);
    useEffect(() => {
        if (isFailed) {
            // notify();
            globalNotifyError(isFailedMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed]);

    // useEffect hook to select the first option on first render
    useEffect(() => {
        if (isFirstRender && customerMap && customerMap?.length > 0) {
            const filteredSingleCustomer = customerMap?.filter((item) => item?.id == id);
            setSelectedCustomer(filteredSingleCustomer[0]); // Select the default option
        }
        if (location?.state?.data) {
            let customerData;
            if (location?.state?.data?.data) {
                customerData = location?.state?.data?.data[0];
            } else {
                customerData = location?.state?.data;
            }
            setSelectedCustomer(customerData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerMap]);

    // useEffect hook to select the first option on first render
    useEffect(() => {
        if (isFirstRender && beneficiaryMap && beneficiaryMap.length > 0) {
            beneficiaryMap?.map((item) => {
                if (item.id === indexBenef) {
                    setChoosedBenef(item); // Select the default option
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryMap]);
    const handleCloseModal = () => {
        setShowOtpField(false);
    };
    return (
        <>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    // flex: Grid,
                    borderRadius: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2,
                        marginBottom: 2
                    }
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={6} sm={6} xs={12}>
                        <Typography variant="h3">
                            {' '}
                            <GlobalBackButton /> Domestic Money Transfer{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Transfer page</Typography>
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Chip
                                color="info"
                                label={`Selected Customer: ${
                                    selectedCustomer ? (selectedCustomer?.name ? selectedCustomer.name : '') : ''
                                }${selectedCustomer?.lname ? ' ' + selectedCustomer.lname : ''}`}
                                sx={{ fontWeight: 'bold' }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            {/* <Chip color="success" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} /> */}
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            {maxLimitLoading ? (
                                <Chip color="warning" label="Loading..." sx={{}} />
                            ) : (
                                <Chip color="warning" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: isMobile ? '20px 10px' : 2,
                    boxShadow: 2,
                    borderRadius: 3
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>{senderno ? 'SENDER MOBILE : ' + senderno : 'SENDER MOBILE : ' + '-'}</h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneAccountNo
                                        ? 'BENEFICIARY A/C : ' + selectedBenefData?.beneAccountNo
                                        : 'BENEFICIARY A/C : ' + '-'}
                                </h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneIfsc
                                        ? 'BENEFICIARY IFSC : ' + selectedBenefData?.beneIfsc
                                        : 'BENEFICIARY IFSC : ' + '-'}
                                </h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneMobile
                                        ? 'BENEFICIARY Mobile : ' + selectedBenefData?.beneMobile
                                        : 'BENEFICIARY Mobile : ' + '-'}
                                </h5>
                            </Grid>

                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 2 }}>
                                        <FormControl fullWidth style={{ minWidth: 200 }}>
                                            <InputLabel htmlFor="demo-simple-select-label">IMPS/NEFT</InputLabel>
                                            <Select
                                                labelid="demo-simple-select-label"
                                                id="demo-simple-select-label"
                                                value={paymentMode}
                                                label="mode"
                                                onChange={handleModeChange}
                                            >
                                                <MenuItem value="IMPS">IMPS</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 2 }}>
                                        <Autocomplete
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={choosedBenef}
                                            label="Beneficiary"
                                            onChange={handleBeneficiaryChange}
                                            options={beneficiaryMap ? beneficiaryMap : []}
                                            getOptionLabel={(option) =>
                                                option?.benefName + ' ' + option?.benefMname + ' ' + option?.benefLname
                                                    ? option?.benefName + ' ' + option?.benefMname + ' ' + option?.benefLname
                                                    : ''
                                            }
                                            renderInput={(params) => <TextField {...params} label="Beneficiary" />}
                                        />
                                    </Box>
                                    <Box sx={{ pt: 3, '& > :not(style)': {} }}>
                                        <Tooltip title="Click to add new beneficiary">
                                            {/* <Link to="/dmt/Checkbeneficiary"> */}
                                            <AntButton onClick={handleAddBenefClick} shape="circle" type="primary" icon={<AddIcon />} />
                                            {/* </Link> */}
                                        </Tooltip>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack sx={{ pt: 2 }} direction="row">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                    <TextField
                                        id="outlined-basic"
                                        label="Amount"
                                        variant="outlined"
                                        error={Boolean(amountError)}
                                        helperText={amountError}
                                        onChange={handleAmountChange}
                                    />
                                    {isTrue ? (
                                        <Space
                                            direction="vertical"
                                            style={{
                                                width: '20%',
                                                paddingTop: 20
                                            }}
                                        >
                                            <Spin size="large">
                                                <div className="content" />
                                            </Spin>
                                        </Space>
                                    ) : null}
                                </Stack>
                            </Grid>

                            <h4 style={{ paddingLeft: 40, color: 'red' }}>{!isSuccess ? 'Money transfer failed' : null}</h4>
                            <Grid item lg={12} md={6} sm={6} xs={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={Boolean(amountError) || Boolean(isLoading)}
                                    onClick={handleTransferClick}
                                    fullWidth
                                >
                                    {isLoading ? 'Loading...' : 'Transfer'}
                                </Button>
                                <Modal
                                    title={<Chip color="info" label={'CONFIRM MONEY TRANSFER DETAILS '} sx={{}} />}
                                    open={open}
                                    onOk={handleOk}
                                    okText="Confirm and Pay"
                                    confirmLoading={confirmLoading}
                                    onCancel={handleCancel}
                                    width="85%"
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <p>Customer Name: {selectedCustomer?.name + ' ' + selectedCustomer?.lname}</p>
                                            <p>Date: {new Date().toDateString().split(' ').slice(1).join(' ')}</p>
                                            <p>Beneficiary A/C : {selectedBenefData ? selectedBenefData?.beneAccountNo : ''}</p>
                                            <p>Service Charge : {amountResponse ? amountResponse.newserviceCharge : ''}</p>
                                            <p>GST : {amountResponse ? amountResponse.gst : ''}</p>
                                        </Col>

                                        <Col span={12}>
                                            <p>Beneficiary Name: {selectedBenefData?.beneName}</p>
                                            <p>Payment Mode: {paymentMode}</p>
                                            <p>Beneficiary IFSC : {selectedBenefData ? selectedBenefData?.beneIfsc : ''}</p>
                                            <p>Effective Commission : {amountResponse ? amountResponse.agent_commission : ''}</p>
                                            <p>TDS : {amountResponse ? amountResponse.tds : ''}</p>
                                            {plansToDisplay.gold.upgradeStatus === 1 && (
                                                <p>Total Commission : {amountResponse ? amountResponse.totCommi : ''}</p>
                                            )}
                                            {/* <p>Total Debit Amount : {amountResponse ? amountResponse.agentDebitAmount : ''}</p> */}
                                        </Col>
                                    </Row>
                                    {plansToDisplay.normal.upgradeStatus === 1 || plansToDisplay.silver.upgradeStatus === 1 ? (
                                        <CommissionDisplayComponent
                                            amountResponse={amountResponse}
                                            isSilver={plansToDisplay.silver.upgradeStatus === 1}
                                        />
                                    ) : null}
                                    <p style={{ fontWeight: 'bold' }}>
                                        Total Debit Amount: ₹ {amountResponse ? amountResponse.agentDebitAmount : ''}
                                    </p>
                                </Modal>
                            </Grid>
                            {/* </Stack> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            {showOtpField && (
                <FundTransferEkyc
                    showModal={showOtpField}
                    id={id}
                    benAcc={benAcc}
                    beneName={selectedBenefData?.beneName}
                    beneId={benifId}
                    amount={amount}
                    paymentMode={paymentMode}
                    data={data}
                    otp_refernce={OtpRefrenceId}
                    closeModalView={handleCloseModal}
                />
            )}
            <Spinner loading={isCheckLoading} />
        </>
    );
}

import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import ProtectedRouteSuperDistributor from '../ProtectRouteSuperDistributor';
import ListAllBatches from 'views/super-distributor/SD-Employee/ListAllBatches';

//Super Distributor
const DashboardSuperDistributor = Loadable(lazy(() => import('views/super-distributor/Dashboard')));
const ProfileSuperDistributor = Loadable(lazy(() => import('views/super-distributor/Profile')));
const DistributorsSuperDistributor = Loadable(lazy(() => import('views/super-distributor/Distributors')));
const ViewSuperDistributor = Loadable(lazy(() => import('views/super-distributor/ViewDistributor')));
const ListAgents = Loadable(lazy(() => import('views/super-distributor/ListAgents')));
const TransactionAgents = Loadable(lazy(() => import('views/super-distributor/TransactionAgents')));
const EditDistributor = Loadable(lazy(() => import('views/super-distributor/EditDistributor')));
const CreateDistributor = Loadable(lazy(() => import('views/super-distributor/CreateDistributor')));
const WalletRequestSuper = Loadable(lazy(() => import('views/super-distributor/WalletRequest')));
const ApproveWalletSuper = Loadable(lazy(() => import('views/super-distributor/ApproveWallet')));
const BankDetailsSuper = Loadable(lazy(() => import('views/super-distributor/BankDetails')));
const UpiWalletRequest = Loadable(lazy(() => import('views/super-distributor/UpiWalletRequest')));
const WalletRequestQr = Loadable(lazy(() => import('views/super-distributor/WalletRequestQr')));
const TransactionHistorySuper = Loadable(lazy(() => import('views/super-distributor/TransactionHistory')));
const SubDistributorSuper = Loadable(lazy(() => import('views/super-distributor/SubDistributor')));
const Debit = Loadable(lazy(() => import('views/super-distributor/Debit')));
const QrOnboardingSu = Loadable(lazy(() => import('views/super-distributor/Onboarding/QrOnboarding')));
const PosOnboardingSu = Loadable(lazy(() => import('views/super-distributor/Onboarding/PosOnboarding')));
const BcAgentActivationSu = Loadable(lazy(() => import('views/super-distributor/Onboarding/BcAgentActivation')));
const OnboardingSu = Loadable(lazy(() => import('views/super-distributor/Onboarding/Onboarding')));
const ListAllMerchants = Loadable(lazy(() => import('views/super-distributor/Onboarding/ListOnboarding')));
const ViewMerchant = Loadable(lazy(() => import('views/super-distributor/Onboarding/ViewMerchants')));
const AllMerchants = Loadable(lazy(() => import('views/super-distributor/AllMerchants')));
const Allbc = Loadable(lazy(() => import('views/super-distributor/AllBc')));
const MerchantView = Loadable(lazy(() => import('views/super-distributor/ViewMerchants')));
const TransactionMerchants = Loadable(lazy(() => import('views/super-distributor/TransactionMerchants')));
const MyTransactionsSuper = Loadable(lazy(() => import('views/super-distributor/MyTransactions')));
const RedirectBcDashboard = Loadable(lazy(() => import('views/super-distributor/RedirectBcDashboard')));
const SoundBox = Loadable(lazy(() => import('views/super-distributor/SoundBox')));
const ListBcSettlements = Loadable(lazy(() => import('views/super-distributor/ListBcSettlement')));
const SettlementRequest = Loadable(lazy(() => import('views/super-distributor/SettlementRequest')));
const CountList = Loadable(lazy(() => import('views/super-distributor/Counts')));
const CreditToDist = Loadable(lazy(() => import('views/super-distributor/Credit')));
const IncentiveEligibleForm = Loadable(lazy(() => import('views/super-distributor/IncentiveEligibleReport')));
const PosTransactions = Loadable(lazy(() => import('views/super-distributor/Merchant Txn Track/Pos')));
const QrTransactions = Loadable(lazy(() => import('views/super-distributor/Merchant Txn Track/Qr')));
const DmtTransactions = Loadable(lazy(() => import('views/super-distributor/BC Txn Track/Dmt')));
const AepsTransactions = Loadable(lazy(() => import('views/super-distributor/BC Txn Track/Aeps')));
const BbpsTransactions = Loadable(lazy(() => import('views/super-distributor/BC Txn Track/Bbps')));
const RechargeTransactions = Loadable(lazy(() => import('views/super-distributor/BC Txn Track/Recharge')));
const MatmTransactions = Loadable(lazy(() => import('views/super-distributor/BC Txn Track/Matm')));
const CreateSdEmployee = Loadable(lazy(() => import('views/super-distributor/SD-Employee/CreateSdEmployee')));
const ListSdEmployee = Loadable(lazy(() => import('views/super-distributor/SD-Employee/ListSdEmployee')));

export const SuperdistributorRoutes = {
    path: '',
    // element: <AdminLayout />,
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteSuperDistributor element={<DashboardSuperDistributor />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteSuperDistributor element={<ProfileSuperDistributor />} />
        },
        {
            path: 'distributors',
            element: <ProtectedRouteSuperDistributor element={<DistributorsSuperDistributor />} />
        },
        {
            path: 'view-distributors',
            element: <ProtectedRouteSuperDistributor element={<ViewSuperDistributor />} />
        },
        {
            path: 'list-agents',
            element: <ProtectedRouteSuperDistributor element={<ListAgents />} />
        },
        {
            path: 'transactions-agent',
            element: <ProtectedRouteSuperDistributor element={<TransactionAgents />} />
        },
        {
            path: 'edit-distributor',
            element: <ProtectedRouteSuperDistributor element={<EditDistributor />} />
        },
        {
            path: 'create-distributor',
            element: <ProtectedRouteSuperDistributor element={<CreateDistributor />} />
        },
        {
            path: 'walletrequest',
            element: <ProtectedRouteSuperDistributor element={<WalletRequestSuper />} />
        },
        {
            path: 'approvewallet',
            element: <ProtectedRouteSuperDistributor element={<ApproveWalletSuper />} />
        },
        {
            path: 'bankdetails',
            element: <ProtectedRouteSuperDistributor element={<BankDetailsSuper />} />
        },
        {
            path: 'upi-wallet-request',
            element: <ProtectedRouteSuperDistributor element={<UpiWalletRequest />} />
        },
        {
            path: 'qr-wallet-request',
            element: <ProtectedRouteSuperDistributor element={<WalletRequestQr />} />
        },
        {
            path: 'transaction-history',
            element: <ProtectedRouteSuperDistributor element={<TransactionHistorySuper />} />
        },
        {
            path: 'list-subdistributor',
            element: <ProtectedRouteSuperDistributor element={<SubDistributorSuper />} />
        },

        {
            path: 'debit',
            element: <ProtectedRouteSuperDistributor element={<Debit />} />
        },
        {
            path: 'onboarding',
            element: <ProtectedRouteSuperDistributor element={<OnboardingSu />} />
        },
        {
            path: 'qr-onboarding',
            element: <ProtectedRouteSuperDistributor element={<QrOnboardingSu />} />
        },
        {
            path: 'pos-onboarding',
            element: <ProtectedRouteSuperDistributor element={<PosOnboardingSu />} />
        },
        {
            path: 'bcactivation',
            element: <ProtectedRouteSuperDistributor element={<BcAgentActivationSu />} />
        },
        {
            path: 'listallmerchants',
            element: <ProtectedRouteSuperDistributor element={<ListAllMerchants />} />
        },
        {
            path: 'viewMerchant',
            element: <ProtectedRouteSuperDistributor element={<ViewMerchant />} />
        },
        {
            path: 'allmerchants',
            element: <ProtectedRouteSuperDistributor element={<AllMerchants />} />
        },
        {
            path: 'allbc',
            element: <ProtectedRouteSuperDistributor element={<Allbc />} />
        },
        {
            path: 'merchantview',
            element: <ProtectedRouteSuperDistributor element={<MerchantView />} />
        },
        {
            path: 'transactionmerchant',
            element: <ProtectedRouteSuperDistributor element={<TransactionMerchants />} />
        },
        { path: 'bc-dashboard-redirection', element: <ProtectedRouteSuperDistributor element={<RedirectBcDashboard />} /> },
        {
            path: 'my-transactions',
            element: <ProtectedRouteSuperDistributor element={<MyTransactionsSuper />} />
        },
        {
            path: 'listsoundbox',
            element: <ProtectedRouteSuperDistributor element={<SoundBox />} />
        },
        { path: 'listBcSettlements', element: <ProtectedRouteSuperDistributor element={<ListBcSettlements />} /> },
        { path: 'settlement-requests', element: <ProtectedRouteSuperDistributor element={<SettlementRequest />} /> },
        {
            path: 'countlist',
            element: <ProtectedRouteSuperDistributor element={<CountList />} />
        },
        {
            path: 'credit-todist',
            element: <ProtectedRouteSuperDistributor element={<CreditToDist />} />
        },
        {
            path: 'incentivereport',
            element: <ProtectedRouteSuperDistributor element={<IncentiveEligibleForm />} />
        },
        { path: 'pos-transactions', element: <ProtectedRouteSuperDistributor element={<PosTransactions />} /> },
        { path: 'qr-transactions', element: <ProtectedRouteSuperDistributor element={<QrTransactions />} /> },
        { path: 'dmt-transactions', element: <ProtectedRouteSuperDistributor element={<DmtTransactions />} /> },
        { path: 'aeps-transactions', element: <ProtectedRouteSuperDistributor element={<AepsTransactions />} /> },
        { path: 'matm-transactions', element: <ProtectedRouteSuperDistributor element={<MatmTransactions />} /> },
        { path: 'recharge-transactions', element: <ProtectedRouteSuperDistributor element={<RechargeTransactions />} /> },
        { path: 'bbps-transactions', element: <ProtectedRouteSuperDistributor element={<BbpsTransactions />} /> },
        { path: 'createEmployee', element: <ProtectedRouteSuperDistributor element={<CreateSdEmployee />} /> },
        { path: 'listEmployee', element: <ProtectedRouteSuperDistributor element={<ListSdEmployee />} /> },
        { path: 'listAllBatches', element: <ProtectedRouteSuperDistributor element={<ListAllBatches />} /> }
    ]
};

/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
const employeeRole = localStorage.getItem('employeeRole');
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const employee = {
    id: 'newUtilities',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'employee/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons2',
            title: 'Profile',
            type: 'item',
            url: 'employee/profile',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons3',
            title: 'All Merchants',
            type: 'item',
            url: 'employee/listallmerchants',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons4',
            title: 'All Bc Agents',
            type: 'item',
            url: 'employee/allbc',
            breadcrumbs: true
        },
        // {
        //     id: 'icons2211',
        //     title: 'Merchants',
        //     type: 'collapse',
        //     children: [
        //         {
        //             id: 'sample-page211',
        //             title: 'Create Merchants',
        //             type: 'item',
        //             url: '/employee/Create-Merchant',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'tabler-icons521',
        //             title: 'List Merchants',
        //             type: 'item',
        //             url: '/employee/list-merchant',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'tabler-icons09',
            title: 'Incentive Eligible Report',
            type: 'item',
            url: 'employee/incentive-elegible',
            breadcrumbs: true
        },
        {
            id: 'icons22312',
            title: 'Onboarding',
            type: 'collapse',
            children: [
                // {
                //     id: 'tabler-icons31210',
                //     title: 'Onboarding',
                //     type: 'item',
                //     url: 'employee/onboarding',
                //     breadcrumbs: true
                // },
                {
                    id: 'tabler-icons532211',
                    title: 'List',
                    type: 'item',
                    url: '/employee/merchants',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons3',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31210',
                    title: 'POS',
                    type: 'item',
                    url: '/employee/pos-transactions',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532211',
                    title: 'QR',
                    type: 'item',
                    url: '/employee/qr-transactions',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons3',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312111',
                    title: 'DMT',
                    type: 'item',
                    url: '/employee/dmt-transactions',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322111',
                    title: 'BBPS',
                    type: 'item',
                    url: '/employee/bbps-transactions',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322112',
                    title: 'Recharge',
                    type: 'item',
                    url: '/employee/recharge-transactions',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322113',
                    title: 'MATM',
                    type: 'item',
                    url: '/employee/matm-transactions',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322114',
                    title: 'AEPS',
                    type: 'item',
                    url: '/Employee/aeps-transactions',
                    breadcrumbs: false
                }
            ]
        },
        ...(employeeRole === 'teammanager'
            ? [
                  {
                      id: 'icons2231',
                      title: 'TL',
                      type: 'collapse',
                      children: [
                          {
                              id: 'tabler-icons3120',
                              title: 'TL',
                              type: 'item',
                              url: 'employee/tl',
                              breadcrumbs: true
                          },
                          {
                              id: 'tabler-icons53221',
                              title: 'List TL',
                              type: 'item',
                              url: '/employee/listtl',
                              breadcrumbs: false
                          }
                      ]
                  }
              ]
            : []),
        ...(employeeRole === 'teammanager' || employeeRole === 'teamleader'
            ? [
                  {
                      id: 'icons22111',
                      title: 'Employee',
                      type: 'collapse',
                      children: [
                          {
                              id: 'sample-page2111',
                              title: 'Create Employee',
                              type: 'item',
                              url: '/employee/createemployee',
                              breadcrumbs: false
                          },
                          {
                              id: 'tabler-icons521',
                              title: 'List Employee',
                              type: 'item',
                              url: '/employee/listemployee',
                              breadcrumbs: false
                          }
                      ]
                  }
              ]
            : [])
    ]
};

export default employee;

/* eslint-disable */

import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
let merchantEmpID;
const isEmployee = localStorage.getItem('isEmployee');
if (isEmployee === 'true') {
    merchantEmpID = localStorage.getItem('empId');
}
export const commonPost = async (apiEndPoint, payload, params) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers,
                params
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const commonGetRequest = async (apiEndPoint, params) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.get(
            endPoint,

            {
                headers,
                params
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const GET_API_ENDPOINTS = {
    getBalance: 'moneytransfer/superDistributor/balance',
    getApproveWallet: 'moneytransfer/superDistributor/list/Approvewalletrequest',
    getAddBankDetails: 'moneytransfer/superDistributor/addBankDetails',
    // deleteBankDetails: 'moneytransfer/superDistributor/deleteBankDetails',
    getAddress: 'moneytransfer/address_details',
    listDistributor: 'moneytransfer/superDistributor/listdistributor',
    getProfile: 'moneytransfer/superDistributor/profile',
    walletRequests: 'moneytransfer/superDistributor/walletrequest',
    listdistributor: 'moneytransfer/superDistributor/listdistributor',
    getMccForDistributor: 'moneytransfer/superDistributor/Mcccodes',
    verifyPan: 'moneytransfer/superDistributor/verifyPancard',
    verifyBank: 'moneytransfer/superDistributor/verifyBank',
    verifyAadhaar: 'moneytransfer/superDistributor/verifyAdhaar',
    getDistrict: 'moneytransfer/superDistributor/district',
    getState: 'moneytransfer/superDistributor/states',
    getMcc: 'moneytransfer/superDistributor/mcc',
    getBussiness: 'moneytransfer/superDistributor/business',
    getCity: 'moneytransfer/superDistributor/fetchCity',
    getBank: 'moneytransfer/superDistributor/fetchBank',
    selectAllEmployee: 'moneytransfer/superDistributor/selectAllEmployees',
    listAllMerchants: 'moneytransfer/superDistributor/listAllMerchants',
    listSubdistributors: 'moneytransfer/superDistributor/listSubdistributor'
};
export const POST_API_ENDPOINTS = {
    approveWalletRequest: 'moneytransfer/superDistributor/ApproveWalletrequest',
    rejectWalletRequest: 'moneytransfer/superDistributor/RejectWalletrequest',
    updateBankDetails: 'moneytransfer/superDistributor/updateBankDetails',
    storeBankDetails: 'moneytransfer/superDistributor/storeBankDetails',
    storeDistributor: 'moneytransfer/superDistributor/StoredetailsDistributor',
    viewDistributor: 'moneytransfer/superDistributor/viewdistributor',
    debitBalance: 'moneytransfer/superDistributor/DebitbalancefromDistributor',
    AgentDebitBalance: 'moneytransfer/superDistributor/DebitbalancefromAgents',
    editDistributor: 'moneytransfer/superDistributor/editdistributor',
    editDetailsDistributor: 'moneytransfer/superDistributor/EditdetailsDistributor',
    listMerchant: 'moneytransfer/superDistributor/listmerchants',
    resetPassword: 'moneytransfer/superDistributor/Setresetpassword',
    listMerchants: 'moneytransfer/superDistributor/listtxnmerchants',
    qrWalletTransfer: 'moneytransfer/superDistributor/GetQrwallettransfer',
    storeWalletRequest: 'moneytransfer/superDistributor/StoreDetailsWalletrequest',
    listMerchantInDistributor: 'moneytransfer/superDistributor/listMerchantInDistributor',
    allTransaction: 'moneytransfer/superDistributor/allTransaction',
    emailOtp: 'moneytransfer/superDistributor/sendEmailOtp',
    emailOtpVerify: 'moneytransfer/superDistributor/VerifyEmailOTP',
    onboard: 'moneytransfer/superDistributor/StoreagentdetailsNew',
    qronboard: 'moneytransfer/superDistributor/onboard/QrOnboarding',
    posOnboard: 'moneytransfer/superDistributor/onboard/PosOnboarding',
    Aeps: 'moneytransfer/superDistributor/storeAepsDetails',
    changeTm: 'moneytransfer/superDistributor/onchangeTm',
    changeTl: 'moneytransfer/superDistributor/onchangeTl',
    mapping: 'moneytransfer/superDistributor/UpdateMerchantEmployeeMapping',
    ResendOTP: 'moneytransfer/superDistributor/ResendOTP',
    VerifyOTP: 'moneytransfer/superDistributor/VerifyOTP',
    getAllonboarded: 'moneytransfer/superDistributor/merchantsList',
    viewMerchant: 'moneytransfer/superDistributor/viewMerchant',
    listAllmerchants: 'moneytransfer/superDistributor/listAllmerchants',
    allbc: 'moneytransfer/superDistributor/listBc',
    viewMerchantDetails: 'moneytransfer/superDistributor/viewMerchantDetails',
    merchantTransactions: 'moneytransfer/superDistributor/view/MerchantTransactions',
    ssoBcDashboard: 'moneytransfer/superDistributor/sso/AgentLogin',
    ConvertAsAgent: 'moneytransfer/superDistributor/onboard/ConvertAsAgent',
    listmytransactions: 'moneytransfer/superDistributor/listmytransactions',
    deleteBankDetails: 'moneytransfer/superDistributor/deleteBankDetails',
    soundBox: 'moneytransfer/superDistributor/soundbox/activation',
    listSoundBox: 'moneytransfer/superDistributor/soundbox/get-details',
    StoredetailsSubDistributor: 'moneytransfer/superDistributor/StoredetailsSubDistributor',
    viewSubdistributor: 'moneytransfer/superDistributor/viewSubdistributor',
    editSubdistributor: 'moneytransfer/superDistributor/EditdetailsSubDistributor',
    creditToDist: 'moneytransfer/superDistributor/CreditbalancetoDistributor',
    checkPermission: 'moneytransfer/superDistributor/checkPermission',
    qrStatusChecker: 'moneytransfer/superDistributor/indexQrOnboarding',
    IncentiveReportList: 'moneytransfer/superDistributor/ListcheckBCExport',
    graphData: 'moneytransfer/superDistributor/graphData',
    signSDistAgreement: 'moneytransfer/superDistributor/sign-SDistAgreement',
    getSDistAgreement: 'moneytransfer/superDistributor/get-SDistAgreement',
    listBcSettlements: 'moneytransfer/superDistributor/listBcSettlements',
    listAllSettlements: 'moneytransfer/superDistributor/listAllSettlements',
    StoreSettlements: 'moneytransfer/superDistributor/StoreSettlements',
    callToAgents: 'moneytransfer/superDistributor/Calltoagents',
    aadharValidation: 'moneytransfer/superDistributor/aadharValidateStep1',
    aadharValidateOtp: 'moneytransfer/superDistributor/aadharValidateStep2',
    serviceList: 'moneytransfer/superDistributor/report-list',
    listAllEmployees: 'moneytransfer/superDistributor/ListAllEmployees',
    CreateEmployee: 'moneytransfer/superDistributor/CreateEmployee',
    ListSdEmployee: 'moneytransfer/superDistributor/ListEmployee',
    EditEmployee: 'moneytransfer/superDistributor/EditEmployee',
    AddFundstoEmployee: 'moneytransfer/superDistributor/AddFundstoEmployee',
    LedgerEmployee: 'moneytransfer/superDistributor/LedgerEmployee',
    setMdrCharges: 'moneytransfer/superDistributor/Setcharges/Mdr',
    ListAllBatches: 'moneytransfer/superDistributor/ListAllBatches',
    UpdatestatusBatches: 'moneytransfer/superDistributor/UpdatestatusBatches'
};
export const getBalanceApi = async (params) => {
    //API FOR FETCHING SUPERDISTRIBUTOR BALANCE
    const response = await commonGetRequest(GET_API_ENDPOINTS.getBalance, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getApproveWallet = async (params) => {
    //API FOR FETCHING SUPERDISTRIBUTOR APPROVE WALLET
    const response = await commonGetRequest(GET_API_ENDPOINTS.getApproveWallet, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const fetchApproveWalletRequest = async (payload) => {
    //API FOR Approve wallet
    const response = await commonPost(POST_API_ENDPOINTS.approveWalletRequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const fetchRejectWalletRequest = async (payload) => {
    //API FOR reject wallet
    const response = await commonPost(POST_API_ENDPOINTS.rejectWalletRequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getAddBankDetails = async (params) => {
    //API FOR FETCHING SUPERDISTRIBUTOR Add bank details
    const response = await commonGetRequest(GET_API_ENDPOINTS.getAddBankDetails, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const updateBankDetails = async (payload) => {
    //API FOR update bank
    const response = await commonPost(POST_API_ENDPOINTS.updateBankDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const deleteBankDetails = async (payload) => {
    //API FOR delete bank
    const response = await commonPost(POST_API_ENDPOINTS.deleteBankDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const storeBankDetails = async (payload) => {
    //API FOR store bank
    const response = await commonPost(POST_API_ENDPOINTS.storeBankDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getAddress = async (params) => {
    //API FOR delete bank
    const response = await commonGetRequest(GET_API_ENDPOINTS.getAddress, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const storeDistributor = async (payload) => {
    //API FOR store dist
    const response = await commonPost(POST_API_ENDPOINTS.storeDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewDistributors = async (payload) => {
    //API FOR view dist
    const response = await commonPost(POST_API_ENDPOINTS.viewDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const fetchDebitBalance = async (payload) => {
    //API FOR view dist
    const response = await commonPost(POST_API_ENDPOINTS.debitBalance, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const fetchAgentDebitBalance = async (payload) => {
    //API FOR view dist
    const response = await commonPost(POST_API_ENDPOINTS.AgentDebitBalance, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getListDistributor = async (params) => {
    //API FOR list distributor
    const response = await commonGetRequest(GET_API_ENDPOINTS.listDistributor, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editDistributor = async (payload) => {
    //API FOR edit dist
    const response = await commonPost(POST_API_ENDPOINTS.editDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editDetailsDistributor = async (payload) => {
    //API FOR edit dist
    const response = await commonPost(POST_API_ENDPOINTS.editDetailsDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listMerchant = async (payload) => {
    //API FOR list merchant
    const response = await commonPost(POST_API_ENDPOINTS.listMerchant, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getProfileApi = async (params) => {
    //API FOR profile
    const response = await commonGetRequest(GET_API_ENDPOINTS.getProfile, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const resetPassword = async (payload) => {
    //API FOR list merchant
    const response = await commonPost(POST_API_ENDPOINTS.resetPassword, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listMerchants = async (payload) => {
    //API FOR list merchant
    const response = await commonPost(POST_API_ENDPOINTS.listMerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const qrWalletTransfer = async (payload) => {
    //API FOR wallet transfer
    const response = await commonPost(POST_API_ENDPOINTS.qrWalletTransfer, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const walletRequests = async (params) => {
    //API FOR walletRequests
    const response = await commonGetRequest(GET_API_ENDPOINTS.walletRequests, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const storeWalletRequest = async (payload) => {
    //API FOR wallet request
    const response = await commonPost(POST_API_ENDPOINTS.storeWalletRequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listMerchantInDist = async (payload) => {
    //API FOR list merchant in dist
    const response = await commonPost(POST_API_ENDPOINTS.listMerchantInDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const allTransactions = async (payload) => {
    //API FOR all transactions
    const response = await commonPost(POST_API_ENDPOINTS.allTransaction, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ssoBcDashboardApi = async (payload) => {
    //API FOR redirecting to BC dashboard
    const response = await commonPost(POST_API_ENDPOINTS.ssoBcDashboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ConvertAsAgentApi = async (payload) => {
    //API FOR Converting as an agent
    const response = await commonPost(POST_API_ENDPOINTS.ConvertAsAgent, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listmytransactionsApi = async (payload) => {
    //API FOR LISTING SUPER DIST TRANSACTIONS
    const response = await commonPost(POST_API_ENDPOINTS.listmytransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const listdistributor = async (params) => {
    //API FOR walletRequests
    const response = await commonGetRequest(GET_API_ENDPOINTS.listdistributor, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getVerifyPanApi = async (params) => {
    //API FOR PAN VERIFY
    const response = await commonGetRequest(GET_API_ENDPOINTS.verifyPan, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listSubdistributorsApi = async (params) => {
    //API FOR LISTING SUB DISTRIBUTORS
    const response = await commonGetRequest(GET_API_ENDPOINTS.listSubdistributors, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const checkPermissionApi = async (payload) => {
    //API FOR Onboard
    const response = await commonPost(POST_API_ENDPOINTS.checkPermission, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getVerifyBank = async (params) => {
    //API FOR PAN VERIFY
    const response = await commonGetRequest(GET_API_ENDPOINTS.verifyBank, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getVerifyAadhaar = async (params) => {
    //API FOR AADAR VERIFY
    const response = await commonGetRequest(GET_API_ENDPOINTS.verifyAadhaar, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getDistrictApi = async (params) => {
    //API FOR Get Dist
    const response = await commonGetRequest(GET_API_ENDPOINTS.getDistrict, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getStateApi = async (params) => {
    //API FOR Get State
    const response = await commonGetRequest(GET_API_ENDPOINTS.getState, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getMccApi = async (params) => {
    //API FOR Get Mcc
    const response = await commonGetRequest(GET_API_ENDPOINTS.getMcc, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBussinessApi = async (params) => {
    //API FOR Get Bussiness
    const response = await commonGetRequest(GET_API_ENDPOINTS.getBussiness, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getCityApi = async (params) => {
    //API FOR Get CITY
    const response = await commonGetRequest(GET_API_ENDPOINTS.getCity, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getEmailOtp = async (payload) => {
    //API FOR Email OTp
    const response = await commonPost(POST_API_ENDPOINTS.emailOtp, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getEmailOtpVErify = async (payload) => {
    //API FOR Email OTp Verify
    const response = await commonPost(POST_API_ENDPOINTS.emailOtpVerify, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const onboardApi = async (payload) => {
    //API FOR Onboard
    const response = await commonPost(POST_API_ENDPOINTS.onboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBankApi = async (params) => {
    //API FOR Get Bank
    const response = await commonGetRequest(GET_API_ENDPOINTS.getBank, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const qronboardApi = async (payload) => {
    //API FOR QR ONBOARD
    const response = await commonPost(POST_API_ENDPOINTS.qronboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const posOnboardApi = async (payload) => {
    //API FOR POS ONBOARD
    const response = await commonPost(POST_API_ENDPOINTS.posOnboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const AepsApi = async (payload) => {
    //API FOR Get Aeps

    const response = await commonPost(POST_API_ENDPOINTS.Aeps, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const resendOtpApi = async (payload) => {
    //API FOR SENDING AND RESENDING OTP
    const response = await commonPost(POST_API_ENDPOINTS.ResendOTP, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const verifyOtpApi = async (payload) => {
    //API FOR VERIFYING OTP
    const response = await commonPost(POST_API_ENDPOINTS.VerifyOTP, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getListAllMerchants = async (params) => {
    //API FOR Get ListAllMErchants
    const response = await commonGetRequest(GET_API_ENDPOINTS.listAllMerchants, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getAllOnboardedApi = async (payload) => {
    //API FOR onboarded list
    const response = await commonPost(POST_API_ENDPOINTS.getAllonboarded, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getMerchantViewApi = async (payload) => {
    //API FOR onboarded list
    const response = await commonPost(POST_API_ENDPOINTS.viewMerchant, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllMerchantsApi = async (payload) => {
    //API FOR onboarded list
    const response = await commonPost(POST_API_ENDPOINTS.listAllmerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllBc = async (payload) => {
    //API FOR list bc's
    const response = await commonPost(POST_API_ENDPOINTS.allbc, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewMerchantDetails = async (payload) => {
    //API FOR view merchants
    const response = await commonPost(POST_API_ENDPOINTS.viewMerchantDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const transactionMerchant = async (payload) => {
    //API FOR view merchants
    const response = await commonPost(POST_API_ENDPOINTS.merchantTransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const AddSoundBoxApi = async (payload) => {
    //API FOR add sound box
    const response = await commonPost(POST_API_ENDPOINTS.soundBox, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getListSoundBox = async (payload) => {
    //API FOR Get ListAllSoundbox
    const response = await commonPost(POST_API_ENDPOINTS.listSoundBox, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const StoredetailsSubDistributorApi = async (payload) => {
    //API FOR CREATING SUB DISTRIBUTOR
    const response = await commonPost(POST_API_ENDPOINTS.StoredetailsSubDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getIncentiveReportList = async (payload) => {
    //API FOR Get ListAllSoundbox
    const response = await commonPost(POST_API_ENDPOINTS.IncentiveReportList, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getserviceList = async (payload) => {
    //API FOR Get ListAllSoundbox
    const response = await commonPost(POST_API_ENDPOINTS.serviceList, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewSubdistributorApi = async (payload) => {
    //API FOR FETCHING DETAILS OF ONE SUB DISTRIBUTOR
    const response = await commonPost(POST_API_ENDPOINTS.viewSubdistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editSubdistributorApi = async (payload) => {
    //API FOR EDITING DETAILS OF ONE SUB DISTRIBUTOR
    const response = await commonPost(POST_API_ENDPOINTS.editSubdistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const creditToDist = async (payload) => {
    //API FOR Credit To Dist
    const response = await commonPost(POST_API_ENDPOINTS.creditToDist, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const statusCheckerApi = async (payload) => {
    //API FOR qr status checker
    const response = await commonPost(POST_API_ENDPOINTS.qrStatusChecker, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const graphDataApi = async (payload) => {
    //API FOR qr status checker
    const response = await commonPost(POST_API_ENDPOINTS.graphData, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const signSDistAgreementApi = async (payload) => {
    //API FOR Super dist agreement signing
    const response = await commonPost(POST_API_ENDPOINTS.signSDistAgreement, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getSDistAgreementApi = async (payload) => {
    //API FOR getting dist agreement
    const response = await commonPost(POST_API_ENDPOINTS.getSDistAgreement, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listBcSettlementsApi = async (payload) => {
    //API FOR getting bc settlements list
    const response = await commonPost(POST_API_ENDPOINTS.listBcSettlements, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllSettlementsApi = async (payload) => {
    //API FOR getting all super dist settlements list
    const response = await commonPost(POST_API_ENDPOINTS.listAllSettlements, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const storeSettlementsApi = async (payload) => {
    //API FOR requesting settlement for SD
    const response = await commonPost(POST_API_ENDPOINTS.StoreSettlements, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const callToAgentApi = async (payload) => {
    //API FOR Agent Call
    const response = await commonPost(POST_API_ENDPOINTS.callToAgents, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const aadharValidateApi = async (payload) => {
    //API FOR Agent Call
    const response = await commonPost(POST_API_ENDPOINTS.aadharValidation, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const aadharValidateOtpApi = async (payload) => {
    //API For Aadhaar validate otp
    const response = await commonPost(POST_API_ENDPOINTS.aadharValidateOtp, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllEmployeeApi = async (payload) => {
    //API For List All Employee
    const response = await commonPost(POST_API_ENDPOINTS.listAllEmployees, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const createEmployeeApi = async (payload) => {
    //API For creating super distributor employee
    const response = await commonPost(POST_API_ENDPOINTS.CreateEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ListSdEmployeeApi = async (payload) => {
    //API For listing super distributor employees
    const response = await commonPost(POST_API_ENDPOINTS.ListSdEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const updateEmployeeApi = async (payload) => {
    //API For editing super distributor employees
    const response = await commonPost(POST_API_ENDPOINTS.EditEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const AddFundstoEmployeeApi = async (payload) => {
    //API For adding fund to super distributor employees
    const response = await commonPost(POST_API_ENDPOINTS.AddFundstoEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const LedgerEmployeeApi = async (payload) => {
    //API For ledger for super distributor employees
    const response = await commonPost(POST_API_ENDPOINTS.LedgerEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const setMdrChargesApi = async (payload) => {
    //API For setting mdr charges for merchants
    const response = await commonPost(POST_API_ENDPOINTS.setMdrCharges, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ListAllBatchesApi = async (payload) => {
    //API For setting mdr charges for merchants
    const response = await commonPost(POST_API_ENDPOINTS.ListAllBatches, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const UpdatestatusBatchesApi = async (payload) => {
    //API For approve and reject batches
    const response = await commonPost(POST_API_ENDPOINTS.UpdatestatusBatches, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
